import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../api/profile';
import { DownloadBox, UploadBox } from '../../common/form';
import { updateProfile } from '../../redux/reducers/auth-reducer';
import { decryptData } from '../../utility/DecryptHelper';

const ProfileComponent = ({ onDocUpdate }) => {
  const { can_add_bank } = useSelector((state) => state.authReducer.rise);
  const { isMutualFundInScope, email, mobile_number, username } = useSelector(
    (state) => state.authReducer
  );

  const [profileData, setProfileData] = useState({});
  const [isMinor, setIsMinor] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isNri, setIsNri] = useState(false);
  const dispatch = useDispatch();
  const minor = '02';
  const individual = '01';
  const nrinrenro = ['11', '21', '24'];
  useEffect(() => {
    getUserDetails(true).then((res) => {
      if (res && res.data) {
        const profileObj = decryptData(res.data.data);
        if (
          profileObj.data.tax_status &&
          profileObj.data.tax_status.id === minor
        )
          setIsMinor(true);
        if (
          profileObj.data.tax_status &&
          profileObj.data.tax_status.id === individual
        )
          setIsIndividual(true);
        if (
          profileObj.data.tax_status &&
          nrinrenro.includes(profileObj.data.tax_status.id)
        )
          setIsNri(true);
        setProfileData(profileObj.data);
        const { pan_name, user_profile_image, can_add_bank, tax_status } =
          profileObj.data;
        dispatch(
          updateProfile({
            user_profile_picture: user_profile_image,
            user_name: pan_name,
            can_add_bank,
            tax_status,
          })
        );
      }
    });
  }, []);

  const updateFATCA = (file) => {
    onDocUpdate('signed_fatca', file);
  };
  const updateAOF = (file) => {
    onDocUpdate('signed_aof', file);
  };
  const uploadDownloadBox =
    isMinor ||
    isIndividual ||
    isNri ||
    !profileData.tax_status ? null : can_add_bank ? null : (
      <>
        <div className='row mx-0 align-items-center'>
          <div className='col-sm-6 px-0'>
            <DownloadBox
              id='downloadAOF'
              title='Download AOF'
              url={
                profileData.user_document &&
                profileData.user_document.aof_without_sign
              }
              value={
                profileData.user_document &&
                profileData.user_document.aof_without_sign.split('/').pop()
              }
            />
          </div>
          <div className='col-sm-6'>
            <UploadBox
              id='uploadAOF'
              value={
                profileData.user_document &&
                profileData.user_document.aof_with_sign.split('/').pop()
              }
              onChange={updateAOF}
              title='Upload AOF'
              accept={['pdf', 'jpg', 'jpeg', 'png']}
            />
          </div>
        </div>
        <div className='row mx-0 align-items-center'>
          <div className='col-sm-6 px-0'>
            <DownloadBox
              id='downloadFATCH'
              title='Download FATCA'
              url={
                profileData.user_document &&
                profileData.user_document.fatca_without_sign
              }
              value={
                profileData.user_document &&
                profileData.user_document.fatca_without_sign.split('/').pop()
              }
            />
          </div>
          <div className='col-sm-6'>
            <UploadBox
              id='upload-FATCA'
              value={
                profileData.user_document &&
                profileData.user_document.fatca_with_sign.split('/').pop()
              }
              onChange={updateFATCA}
              title='Upload FATCA'
              accept={['pdf', 'jpg', 'jpeg', 'png']}
            />
          </div>
        </div>
        <div className='profile-note my-2 opacity-50 small'>
          <span>
            Please sign & stamp the AOF and FATCA form and upload it here
          </span>
        </div>
      </>
    );
  return (
    <div className='profile-detail-section p-4'>
      <Form.Group className='col-sm-6 px-0 my-3'>
        <Form.Label className=' opacity-50  mb-1'>
          {isMinor ? 'Guardian Name' : 'Name'}
        </Form.Label>
        <Form.Control
          type='text'
          id='name'
          disabled
          defaultValue={username?.length > 0 ? username : profileData.pan_name}
          className={'updated-input pl-0 font-weight-medium'}
        />
      </Form.Group>
      {isMinor ? (
        <Form.Group className='col-sm-6 px-0 my-3'>
          <Form.Label className=' opacity-50  mb-1'>Minor Name</Form.Label>
          <Form.Control
            type='text'
            id='name'
            disabled
            defaultValue={profileData.minor_full_name}
            className={'updated-input pl-0 font-weight-medium'}
          />
        </Form.Group>
      ) : null}
      <Form.Group className='col-sm-6 px-0 my-3'>
        <Form.Label className=' opacity-50  mb-1'>Mobile Number</Form.Label>
        <Form.Control
          type='text'
          id='contact-number'
          disabled
          defaultValue={mobile_number}
          className={'updated-input pl-0 font-weight-medium'}
        />
      </Form.Group>
      <Form.Group className='col-sm-6 px-0 my-3'>
        <Form.Label className=' opacity-50  mb-1'>Email ID</Form.Label>
        <Form.Control
          type='text'
          id='emailId'
          disabled
          defaultValue={email}
          className={'updated-input pl-0  form-control font-weight-medium'}
        />
      </Form.Group>
      {isMutualFundInScope && (
        <Form.Group className='col-sm-6  px-0 my-3'>
          <Form.Label className=' opacity-50  mb-1'>PAN</Form.Label>
          <Form.Control
            type='text'
            id='pan-number'
            disabled
            defaultValue={profileData.pan}
            className={'updated-input pl-0 font-weight-medium'}
          />
        </Form.Group>
      )}
      {isMutualFundInScope && (
        <Form.Group className='col-sm-6  px-0 my-3'>
          <Form.Label className=' opacity-50  mb-1'>Tax Status</Form.Label>
          <Form.Control
            type='text'
            id='tax-status'
            disabled
            defaultValue={
              profileData &&
              profileData.tax_status &&
              profileData.tax_status.tax_status
            }
            className={'updated-input pl-0 font-weight-medium'}
          />
        </Form.Group>
      )}
      {isMutualFundInScope && uploadDownloadBox}
    </div>
  );
};
export default ProfileComponent;
