import axios from '../axios';

interface ChangePasswordResponse {
  data: {
    status: {
      code: number;
      message: string;
    };
    data: string;
  };
}

export const resetPassword = (
    postData: FormData
  ): Promise<ChangePasswordResponse> => {
    return axios.post('users/change-password/', postData);
  };