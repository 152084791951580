import { Form } from 'react-bootstrap';
import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { resetPassword } from '../../api/mfApi/changePassword';
import {
  PasswordValidationMessages,
  validationPatterns,
  ENTER_KEY_CODE,
} from '../../utility/changePassword';
import Toast from '../../common/toast/Toast';

const ChangePassword = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [populateMessage, setPopulateMessage] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const passwordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let password = event.target.value || '',
      passwordErr = '',
      confirmPasswordErr = '',
      submitBtnState = true;
    if (!password || !validationPatterns.passwordRegex(8, 15).test(password)) {
      setPopulateMessage(false);
      passwordErr = PasswordValidationMessages.PREPOPULATE_PASSWORD;
    } else if (!confirmPassword) {
      confirmPasswordErr = PasswordValidationMessages.PASSWORD_MUST_MATCH;
    } else {
      submitBtnState = false;
      passwordErr = '';
    }
    setConfirmPasswordError(confirmPasswordErr);
    setPasswordError(passwordErr);
    setIsButtonDisabled(submitBtnState);
    setPassword(password);
  };
  const currentPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    let currentPassword = event.target.value || '';
    setCurrentPassword(currentPassword);
  };
  const confirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    let confirmPassword = event.target.value || '',
      passwordErr = '',
      submitBtnState = true;
    if (!confirmPassword) {
      passwordErr = PasswordValidationMessages.PREPOPULATE_PASSWORD;
    } else if (password !== confirmPassword) {
      passwordErr = PasswordValidationMessages.PASSWORD_MUST_MATCH;
    } else {
      submitBtnState = false;
      passwordErr = '';
    }
    setConfirmPasswordError(passwordErr);
    setIsButtonDisabled(submitBtnState);
    setConfirmPassword(confirmPassword);
  };
  const submit = async () => {
    const data = new FormData();
    data.append('confirm_password', confirmPassword);
    data.append('current_password', currentPassword);
    data.append('new_password', password);
    try {
      const response = await resetPassword(data);
      setPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
      if (response.data.status.code === 1) {
        Toast.success(response.data.status.message);
      } else Toast.error(response.data.status.message);
    } catch {
      Toast.error('Error updating password');
    }
  };
  const enterHandler = (event: KeyboardEvent): void => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      submit();
    }
  };

  return (
    <div className='profile-detail-section p-4 d-flex flex-column justify-content-center align-items-center'>
      <div className='h5 font-weight-bold my-4'>Reset Password</div>
      <Form.Group className='col-sm-6  px-0'>
        <Form.Control
          type='password'
          placeholder='Enter Current Password'
          onChange={currentPasswordChange}
          value={currentPassword}
          className={'font-weight-medium '}
        />
      </Form.Group>
      <Form.Group className='col-sm-6  px-0'>
        <Form.Control
          type='password'
          placeholder='Enter New Password'
          onChange={passwordChange}
          value={password}
          className={
            'font-weight-medium ' + (passwordError ? 'is-invalid' : '')
          }
          onKeyDown={enterHandler}
        />
        <Form.Control.Feedback type='invalid'>
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='col-sm-6  px-0'>
        <Form.Control
          type='password'
          placeholder='Confirm New Password'
          onChange={confirmPasswordChange}
          value={confirmPassword}
          className={
            'font-weight-medium ' + (confirmPasswordError ? 'is-invalid' : '')
          }
          onKeyDown={enterHandler}
        />
        <Form.Control.Feedback type='invalid'>
          {confirmPasswordError}
        </Form.Control.Feedback>
        {populateMessage && (
          <p
            style={{
              color: '#dc3545',
              fontSize: 'small',
              marginTop: '10px',
            }}
          >
            {PasswordValidationMessages.PREPOPULATE_PASSWORD}
          </p>
        )}
      </Form.Group>

      <div className='mt-2'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={submit}
          disabled={isButtonDisabled}
        >
          Update Password
        </button>
      </div>
    </div>
  );
};
export default ChangePassword;
