import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
const AddFundTimeModal = ({ show, onClose, fundName }) => {
  let fundArray = fundName.data;
  const isLiquidFundRestricted = false;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {windowDimensions.width > 480 ? (
        <Modal
          show={show}
          size='md'
          aria-labelledby='confirmation-modal'
          onHide={onClose}
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton className='justify-content-center mt-2'>
            <span className='font-weight-medium h5 text-center'>
              CUT OFF TIME HAS PASSED
            </span>
          </Modal.Header>
          <Modal.Body className='text-align-justify'>
            {isLiquidFundRestricted && fundArray && (
              <span>{`The below mentioned fund(s) have cut-off period from ${fundArray[0].from} to ${fundArray[0].to}. You will be able to place an order for these funds after 3pm. You can leave them in the checkout cart and place the order later. `}</span>
            )}
          </Modal.Body>
          <Modal.Footer className='mb-4 float-left py-0 justify-content-start'>
            <span className='h5'>
              {fundArray &&
                fundArray.length &&
                fundArray.map((funds) => (
                  <p key={funds.fund} className='c-blue'>
                    {funds.fund}
                  </p>
                ))}
            </span>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          show={show}
          size='md'
          className='p-4 '
          aria-labelledby='confirmation-modal'
          onHide={onClose}
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton className='mt-2'>
            <span className='font-weight-medium h5 text-center'>
              CUT OFF TIME HAS PASSED
            </span>
          </Modal.Header>
          <Modal.Body className='text-center'>
            {isLiquidFundRestricted && fundArray && (
              <span className='opacity-50'>{`The below mentioned fund(s) have cut-off period from ${fundArray[0].from} to ${fundArray[0].to}. You will be able to place an order for these funds after 3pm. You can leave them in the checkout cart and place the order later. `}</span>
            )}
            <span className='h6 my-3 pt-3 font-weight-bold border-top'>
              {fundArray &&
                fundArray.length &&
                fundArray.map((funds) => (
                  <p key={funds.fund} className='c-blue'>
                    {funds.fund}
                  </p>
                ))}
            </span>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
AddFundTimeModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fundName: PropTypes.string,
};
export default AddFundTimeModal;
