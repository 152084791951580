export enum PasswordValidationMessages {
    PREPOPULATE_PASSWORD = 'Password must be 8 to 15 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.',
    PASSWORD_MUST_MATCH = 'Passwords must match',
  }

  export const validationPatterns = {
    name: /^(?=.{2,50}$)[A-Za-z]+(?: [A-Za-z]+)*$/,
    keyDownName: /^[a-zA-Z ]*$/,
    passwordRegex: (minL: number, maxL: number) => {
      return new RegExp(
        `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{${minL},${maxL}}$`
      );
    },
  };

  export const ENTER_KEY_CODE = 13;